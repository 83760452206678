import React from "react"
import { Link } from "gatsby"
import slug from "slug"

/* Import Global Components */
import DoubleRightArrowSvg from "~components/icon/normal/doubleRightPointer"

/* Import Local Components */
import Slide from "../slide"

/* Import Local Styles */
import "./more-work.css"

const MoreWork = ({ nextProject, previousProject }) => {
  return (
    <Slide title="more-work">
      <div className="container">
        <Link to={`/work/${slug(previousProject.title)}`}>
          <span>{previousProject.title}</span>
          <br />
          <span style={{ display: "inline-block", height: "0.7em" }}>
            <DoubleRightArrowSvg style={{ transform: "rotate(180deg)" }} />{" "}
          </span>
        </Link>
        <p>More Work</p>
        <Link to={`/work/${slug(nextProject.title)}`}>
          <span>{nextProject.title}</span>
          <br />
          <span style={{ display: "inline-block", height: "0.7em" }}>
            <DoubleRightArrowSvg />
          </span>
        </Link>
      </div>
    </Slide>
  )
}

export default MoreWork
