import React from "react"
import slug from "slug"

/* Import Local Components */
import Slide from "../slide"

/* Import Local Styles */
import "./credits.css"

const Credits = ({ title, credits }) => (
  <Slide title="credits">
    <div className="container">
      <div className="title">{title}</div>
      <div className="credits-container">
        {credits.map((credit, index) => (
          <div key={slug(`${credit._key}-${index}`)}>
            <p className="credit">{credit.copy}</p>
            <ul>
              {credit.credited.map((credited, index) => (
                <li key={slug(`${credited._key}-${index}`)}>
                  {credit.credited.length > 1 &&
                  index !== credit.credited.length - 1 ? (
                    <p className="credited">{`${credited},`}</p>
                  ) : (
                    <p className="credited">{`${credited}`}</p>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  </Slide>
)

export default Credits
