import React from "react"
import classnames from "classnames"

/* Import Local Styles */
import "./slide.css"

const Slide = ({ title = null, children }) => (
  <div className={classnames("slide", title)}>{children}</div>
)

export default Slide
