import React from "react"

/* Import Global Components */
import LefPointer from "~components/icon/normal/leftPointer"

/* Import Local Styles */
import "./previous-slide-button.css"

const PreviousSlideButton = () => {
  const goToPreviousSlide = () => {
    if (window && window.fullpage_api) {
      window.fullpage_api.moveSlideLeft()
    }
  }

  return (
    <div className="previous-slide-button">
      <button onClick={goToPreviousSlide}>
        <LefPointer />
      </button>
    </div>
  )
}

export default PreviousSlideButton
