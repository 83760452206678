import React from "react"
import BlockContent from "@sanity/block-content-to-react"

/* Import Local Components */
import Slide from "../slide"

/* Import Local Styles */
import "./text.css"

const Text = ({ text }) => {
  const serializers = {
    marks: {
      link: ({ mark, children }) => {
        const { href } = mark
        return (
          <a href={href} target="_blank" rel="noreferrer noopener">
            {children}
          </a>
        )
      },
    },
  }

  return (
    <Slide title="text">
      <div className="text-container">
        <BlockContent blocks={text} serializers={serializers} />
      </div>
    </Slide>
  )
}

export default Text
