import React from "react"

/* Import Local Components */
import Slide from "../slide"

/* Import Local Styles */
import "./images.css"

const Images = ({ images }) => {
  return (
    <Slide title="images">
      <div className="images-container">
        {images.map(image => {
          return (
            <div className="image-container" key={image._key}>
              <div className="image-wrapper">
                <img
                  src={
                    image.asset.extension === "gif"
                      ? `${image.asset.url}`
                      : `${image.asset.url}?max-w=1440&fit=max&w=1440&fm=png`
                  }
                  alt="Documentation of Work"
                />
              </div>
            </div>
          )
        })}
      </div>
    </Slide>
  )
}

export default Images
