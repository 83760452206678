import React from "react"

/* Import Local Styles */
import "../icon.css"

const OffsiteArrowSvg = () => (
  <svg
    className="icon no-fill"
    viewBox="0 0 31 30"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M13.6343 20.4128C15.4664 17.8695 17.6357 15.2046 20.1535 12.3848C22.5926 9.66458 25.841 6.14817 29.8986 1.8356C29.8874 1.86878 29.8874 1.89089 29.8761 1.92407C29.7862 2.34427 29.7075 2.8861 29.6176 3.54957C29.6176 3.57169 29.6064 3.58275 29.6064 3.60486C29.0669 5.60634 28.5386 7.44194 28.0328 9.11168C27.5382 10.7151 26.9537 12.4843 26.2793 14.4084C26.2568 14.4747 26.2456 14.5522 26.2681 14.6185C26.2568 15.1493 26.403 16.4983 28.1789 16.5094C28.2014 16.5094 28.2239 16.5094 28.2463 16.5094C28.2688 16.5094 28.2913 16.5094 28.3138 16.5094C29.5727 16.4652 30.0447 15.2267 30.1796 14.718C30.2133 14.6517 30.2358 14.5743 30.2246 14.5079C30.1459 12.4954 30.1571 10.5934 30.2695 8.80206C30.3819 6.9554 30.5618 4.76594 30.8203 2.23369C30.8203 2.22263 30.8203 2.21157 30.8203 2.20051C30.8765 1.89089 30.9327 1.51493 31.0001 1.07261C31.0563 0.674527 30.3257 -0.0442343 29.9099 -2.77709e-06C29.4603 0.0552866 29.0781 0.110576 28.7634 0.176923C28.7521 0.176923 28.7409 0.176923 28.7297 0.176923C26.1444 0.431254 23.9189 0.60818 22.0531 0.718759C20.2322 0.81828 18.2989 0.829337 16.2532 0.76299C16.2195 0.76299 16.1858 0.76299 16.1521 0.774048C16.1521 0.774048 13.9378 0.442312 14.0165 2.77552C14.0727 4.33468 16.0509 4.65536 16.0509 4.65536C16.1408 4.68853 16.242 4.68853 16.3319 4.65536C18.2877 3.99189 20.0748 3.41688 21.7159 2.93033C23.4131 2.42167 25.279 1.90195 27.3134 1.38223C27.3359 1.38223 27.3471 1.37117 27.3696 1.37117C28.044 1.28271 28.5948 1.20531 29.0219 1.11684C29.0781 1.10578 29.1343 1.09473 29.1905 1.08367C24.6158 5.21931 20.9853 8.44821 18.2764 10.7593C15.4889 13.1478 12.7913 15.2377 10.1724 17.0402C10.0825 17.0955 13.5556 20.5123 13.6343 20.4128Z"
        className="fill-with-text-color"
      />
      <path
        d="M27.111 19.8929H26.6389V26.3729H3.60805V4.9427H10.1835V4.47827H0V29.9998H10.1835V29.9114H27.111V19.8929Z"
        className="fill-with-text-color"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="31" height="30" className="fill-with-text-color" />
      </clipPath>
    </defs>
  </svg>
)

export default OffsiteArrowSvg
