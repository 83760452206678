import React from "react"

/* Import Global Components */
import RightPointer from "~components/icon/normal/rightPointer"

/* Import Local Styles */
import "./next-slide-button.css"

const NextSlideButton = () => {
  const goToNextSlide = () => {
    if (window && window.fullpage_api) {
      window.fullpage_api.moveSlideRight()
    }
  }

  return (
    <div className="next-slide-button">
      <button onClick={goToNextSlide}>
        <RightPointer />
      </button>
    </div>
  )
}

export default NextSlideButton
