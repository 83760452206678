import React from "react"

/* Import Local Styles */
import "../icon.css"

const PlaySvg = () => (
  <svg
    className="icon no-fill"
    viewBox="0 0 117 117"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M58.5 0C26.1944 0 0 26.1944 0 58.5C0 90.8056 26.1944 117 58.5 117C90.8056 117 117 90.8056 117 58.5C117 26.1944 90.8056 0 58.5 0ZM77.3166 59.401L48.7979 80.1502C48.6415 80.2626 48.4572 80.3296 48.2652 80.3441C48.0733 80.3585 47.881 80.3197 47.7096 80.232C47.5382 80.1443 47.3944 80.011 47.2938 79.8469C47.1932 79.6827 47.1398 79.494 47.1395 79.3014V37.8291C47.1389 37.6362 47.1917 37.4469 47.2921 37.2822C47.3924 37.1174 47.5365 36.9837 47.7082 36.8958C47.8799 36.8079 48.0726 36.7693 48.265 36.7842C48.4573 36.7991 48.6418 36.867 48.7979 36.9804L77.3166 57.7165C77.4514 57.8118 77.5613 57.938 77.6371 58.0846C77.713 58.2311 77.7526 58.3937 77.7526 58.5588C77.7526 58.7238 77.713 58.8864 77.6371 59.0329C77.5613 59.1795 77.4514 59.3057 77.3166 59.401Z"
      className="fill-with-text-color"
    />
  </svg>
)

export default PlaySvg
