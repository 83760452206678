import React, { useState, useEffect, useRef } from "react"
import ReactPlayer from "react-player"

/* Import Global Components */
import Play from "~components/icon/normal/play"

/* Import Local Components */
import Slide from "../slide"

/* Import Local Styles */
import "./hero.css"

const Hero = ({ title, video, openModal }) => {
  const [isReady, setIsReady] = useState(false)
  const backgroundVideoRef = useRef()

  useEffect(() => {
    if (backgroundVideoRef && backgroundVideoRef.current && isReady) {
      backgroundVideoRef.current
        .getInternalPlayer()
        .setAttribute("data-keepplaying", "")
    }
  }, [backgroundVideoRef, isReady])

  return (
    <Slide title="hero">
      <div className="hero-container">
        <p>{title}</p>
        <div className="background-video">
          <ReactPlayer
            className="react-player"
            width="100%"
            height="100%"
            autoPlay
            loop={true}
            volume={0}
            muted={true}
            playing={true}
            playsinline={true}
            onReady={() => {
              setIsReady(true)
            }}
            ref={backgroundVideoRef}
            url={`https://stream.mux.com/${video.asset.playbackId}.m3u8`}
          />
        </div>
        <div className="controls-container">
          <button onClick={openModal}>
            <Play />
          </button>
        </div>
      </div>
    </Slide>
  )
}

export default Hero
