import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import classnames from "classnames"

/* Import Local Components */
import Slide from "../slide"

/* Import Local Styles */
import "./text-and-image.css"

const TextAndImage = ({ text, image, landscapeMode }) => {
  const serializers = {
    marks: {
      link: ({ mark, children }) => {
        const { href } = mark
        return (
          <a href={href} target="_blank" rel="noreferrer noopener">
            {children}
          </a>
        )
      },
    },
  }

  return (
    <Slide
      title={classnames("text-and-image", {
        landscape: landscapeMode,
        portrait: !landscapeMode,
      })}
    >
      <div className="text-and-image-container">
        <div className="text-and-image-image">
          <div className="text-and-image-image-wrapper">
            <img
              src={
                image.asset.extension === "gif"
                  ? `${image.asset.url}`
                  : `${image.asset.url}?max-w=1440&fit=max&w=1440&fm=png`
              }
              alt="Documentation of Work"
            />
          </div>
        </div>
        <div className="text-and-image-text">
          <BlockContent blocks={text} serializers={serializers} />
        </div>
      </div>
      {/*  */}
    </Slide>
  )
}

export default TextAndImage
