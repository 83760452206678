import React from "react"
import BlockContent from "@sanity/block-content-to-react"

/* Import Global Components*/
import OffsiteArrow from "~components/icon/normal/offsiteArrow"

/* Import Local Components */
import Slide from "../slide"

/* Import Local Styles */
import "./endorsement.css"

const Endorsement = ({ endorser, endorserUrl, endorsement }) => {
  const serializers = {
    marks: {
      link: ({ mark, children }) => {
        const { href } = mark
        return (
          <a href={href} target="_blank" rel="noreferrer noopener">
            {children}
          </a>
        )
      },
    },
  }

  return (
    <Slide title="endorsement">
      <div className="endorsement-container">
        <div>
          <BlockContent blocks={endorsement} serializers={serializers} />
        </div>
        {endorserUrl ? (
          <a
            href={endorserUrl}
            target="_blank"
            rel="noreferrer"
            className="endorser"
          >
            {endorser}{" "}
            <span>
              <OffsiteArrow />
            </span>
          </a>
        ) : (
          <p className="endorser">{endorser}</p>
        )}
      </div>
    </Slide>
  )
}

export default Endorsement
