import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import ReactPlayer from "react-player"
import classnames from "classnames"

/* Import Global Components */
import Page from "~components/page"
import Breadcrumbs from "~components/breadcrumbs"
import CrossIconSvg from "~components/icon/normal/cross"

/* Import Local Components */
import Slides from "./components/slides"
import PreviousSlideButton from "./components/previousSlideButton/previousSlideButton"
import NextSlideButton from "./components/nextSlideButton/nextSlideButton"
import Hero from "./components/hero"
import Text from "./components/text"
import TextAndImage from "./components/textAndImage"
import Images from "./components/images"
import Endorsement from "./components/endorsement"
import Credits from "./components/credits"
import MoreWork from "./components/moreWork"

/* Import Local Styles */
import "./work.css"

const Work = ({ data, pageContext }) => {
  // comment
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState()
  const [isPlaying, setIsPlaying] = useState()

  const openModal = () => {
    setIsModalOpen(true)
    setIsPlaying(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    pause()
  }

  const onPlay = () => {
    setIsPlaying(true)
  }

  const onPause = () => {
    setIsPlaying(false)
  }

  const pause = () => {
    setIsPlaying(false)
  }

  useEffect(() => {
    if (activeSlideIndex && activeSlideIndex !== 0) {
      setIsModalOpen(false)
      setIsPlaying(false)
    }
  }, [activeSlideIndex])

  const project = data.sanityProject
  return (
    <Page className="work">
      <Breadcrumbs
        length={project.content.length + 2}
        activeBreadcrumb={activeSlideIndex}
        setActiveSlideIndex={setActiveSlideIndex}
        handleClick={index => {
          if (window && window.fullpage_api) {
            window.fullpage_api.moveTo(1, index)
          }
        }}
      />
      <Slides
        onSlideLeaveCallback={index => {
          setActiveSlideIndex(index)
        }}
      >
        <Hero
          title={project.title}
          video={project.projectVideoMux}
          openModal={openModal}
        />
        {project.content.map(slide => {
          switch (slide.__typename) {
            case "SanityProjectText":
              return <Text key={slide._key} text={slide._rawText} />
            case "SanityProjectImageWithText":
              return (
                <TextAndImage
                  key={slide._key}
                  text={slide._rawText}
                  image={slide.image}
                  landscapeMode={slide.landscapeMode}
                />
              )
            case "SanityProjectImages":
              return <Images key={slide._key} images={slide.images} />
            case "SanityProjectEndorsement":
              return (
                <Endorsement
                  key={slide._key}
                  endorser={slide.endorser}
                  endorserUrl={
                    slide && slide.endorserUrl ? slide.endorserUrl : null
                  }
                  endorsement={slide._rawEndorsement}
                />
              )
            case "SanityProjectCredits":
              return (
                <Credits
                  key={slide._key}
                  title={project.title}
                  credits={slide.projectCredits}
                />
              )
            default:
              return null
          }
        })}
        <MoreWork
          previousProject={pageContext.previousProject}
          nextProject={pageContext.nextProject}
        />
      </Slides>
      <div className={classnames("modal", { open: isModalOpen })}>
        <div className="close">
          <button onClick={closeModal}>
            <CrossIconSvg />
          </button>
        </div>
        <div className="player-wrapper">
          {/* <div className="player-wrapper"> */}
          <ReactPlayer
            controls
            className="react-player"
            width="100%"
            height="100%"
            playing={isPlaying}
            onPlay={onPlay}
            onPause={onPause}
            playsinline={true}
            url={`https://stream.mux.com/${project.modalVideoMux.asset.playbackId}.m3u8`}
          />
          {/* </div> */}
        </div>
      </div>
      <div className="slide-controls">
        <PreviousSlideButton />
        <NextSlideButton />
      </div>
    </Page>
  )
}

export default Work

export const query = graphql`
  query($title: String!) {
    sanityProject(title: { eq: $title }) {
      title
      projectVideoMux {
        asset {
          playbackId
        }
      }
      modalVideoMux {
        asset {
          playbackId
        }
      }
      content {
        ... on SanityProjectCredits {
          _key
          _type
          projectCredits {
            _key
            copy
            credited
          }
        }
        ... on SanityProjectEndorsement {
          _key
          _type
          endorser
          endorserUrl
          _rawEndorsement
        }
        ... on SanityProjectImageWithText {
          _key
          _type
          _rawText(resolveReferences: { maxDepth: 10 })
          image {
            asset {
              url
              extension
            }
          }
          landscapeMode
        }
        ... on SanityProjectImages {
          _key
          _type
          images {
            _key
            asset {
              url
              _id
              extension
            }
          }
        }
        ... on SanityProjectText {
          _key
          _type
          _rawText(resolveReferences: { maxDepth: 10 })
        }
      }
    }
  }
`
